<template>
  <v-dialog fullscreen persistent v-model="local_modal">
    <v-card flat class="fill-height">
      <div class="grey darken-3 non-printable" style="position: relative; width: 100%; height: 42px">
        <v-btn small fab height="32" width="32"
               style="position:absolute; right: 6px; top: 5px;"
               color="secondary" dark
               @click="closeModal"
        >Х</v-btn>
      </div>
      <div style="height: calc(100% - 42px); overflow: hidden" class="white" >
        <component
            :is="componentCurrent"
            :report_id="report_id"
            :report_is_new="report_is_new"
            :report_type="report_type"
            @reportSaved="reportSaved"
            @reportDelete="reportDelete"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";

export default {
  name: "RegulatoryReportWrapper",
  props: {
    report_form: {
      type: String,
      default: ''
    },
    report_id: {
      type: Number,
      default: null
    },
    report_is_new: {
      type: Boolean,
      default: false
    },
    report_type: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      modalAnswer: 'question_answer'
    }),
    componentCurrent() {
      if (this.report_form !== '') {
        return () => import(`@/components/regulatory_reports/${this.report_form}.vue`)
      }
      return null
    }
  },
  data() {
    return {
      modal_answer_watcher: null,
      local_modal: false
    }
  },
  methods: {
    closeModal() {
      const payload = {
        text: `Ви дійсно хочете закрити звіт?`,
        accept_button: true,
        id: `close-${this.report_form}`
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    reportSaved(payload) {
      this.$emit('reportSaved', payload)
    },
    reportDelete(payload) {
      this.$emit('reportDelete', payload)
    },
    watch_modal_answer() {
      this.modal_answer_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === `close-${this.report_form}`) {
                if (payload.answer) {
                  this.local_modal = false
                  this.$emit('closeRegularReportForm')
                }
              }
            }
          }
      )
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.watch_modal_answer()
        } else {
          if (this.modal_answer_watcher) {
            this.modal_answer_watcher()
          }
        }
        this.local_modal = payload
      }
    }
  }
}
</script>


<style scoped lang="scss">

</style>